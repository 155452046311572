<template>
  <TalpaLoaderWrapper v-if="$apollo.queries.assetDimensionData.loading || !assetDimensionData" />
  <CardWidget v-else :title="title" :total="totalFormatted" :trend="trend" :series="series" />
</template>

<script>
import { DateTime } from 'luxon'
import CardWidget from '../../Molecules/CardWidget'
import localesMixin from '@/mixins/locales'
import { TalpaLoaderWrapper } from '@common/components'
import { getChartTrendColors } from '@/utils/chart'
import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/assetDimensionDataKPICard.gql'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

export default {
  inject: ['theme', 'uiSettings'],
  mixins: [localesMixin],
  props: {
    assetDimension: {
      type: Object,
      required: true,
    },
    selectedTimeframeParam: {
      type: Object,
      required: true,
    },
    timeBuckets: {
      type: Array,
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardWidget,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      assetDimensionData: null,
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    assetIds() {
      return this.assets.map(({ id }) => id)
    },
    assetDimensionName() {
      return this.assetDimension?.name ?? null
    },
    title() {
      return this.assetDimension?.nameTranslations
        ? getAssetDimensionNameByLocale(this.assetDimension?.nameTranslations, this.locale)
        : this.assetDimensionName
    },
    increasingTrendColor() {
      return this.assetDimension?.increasingTrendColor ?? ''
    },
    colors() {
      const { chartColor, color } = getChartTrendColors(this.variationAbsolute, this.increasingTrendColor, this.theme)
      return {
        chartColor,
        color,
      }
    },
    series() {
      const unitUI = this.unitUI
      const unitSI = this.unitSI
      const data = this.timeBuckets.map((timeBucket, i) => {
        const found = this.assetDimensionData?.aggrByTimebuckets?.find(f => {
          const time = DateTime.fromISO(f.time)
          return timeBucket.contains(time)
        })
        return [i, found !== undefined ? this.convUnit(found.floatValue, unitSI, unitUI, 2, false) : null]
      })
      return [
        {
          type: 'line',
          data,
          itemStyle: {
            color: this.colors.chartColor,
          },
        },
      ]
    },
    unitSI() {
      return this.assetDimension?.physicalUnitSI ?? null
    },
    unitUI() {
      const unitUIMetric = this.assetDimension?.physicalUnitSI === '%' ? '%' : this.assetDimension?.physicalUnitUIMetric
      const unitUIImperial = this.assetDimension?.physicalUnitSI === '%' ? '%' : this.assetDimension?.physicalUnitUIImperial
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return unitUI
    },
    total() {
      return this.assetDimensionData?.total
    },
    totalFormatted() {
      return this.total !== null ? this.convUnit(this.total, this.unitSI, this.unitUI, 2, true, true) : '-' + this.unitFinder
    },
    variationAbsolute() {
      return this.assetDimensionData?.variation?.absolute
    },
    variationPercentage() {
      return this.assetDimensionData?.variation?.percent
    },
    trend() {
      return {
        color: this.colors.color,
        percent: this.variationPercentage !== null ? this.convUnit(this.variationPercentage, '%', '%', 1, true, true, false) : '- %',
      }
    },
    unitFinder() {
      return this.convUnit(0, this.unitSI, this.unitUI, 2, true, true).slice(1)
    },
  },
  apollo: {
    assetDimensionData: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: this.assetDimensionName,
            },
            timeframe: this.selectedTimeframeParam,
            assets: {
              id_in: this.assetIds,
            },
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || !this.assetDimensionName || this.assets.length < 1
      },
    },
  },
}
</script>
