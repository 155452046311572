var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.queries.assetDimensionData.loading || !_vm.assetDimensionData ? _c('TalpaLoaderWrapper') : _c('CardWidget', {
    attrs: {
      "title": _vm.title,
      "total": _vm.totalFormatted,
      "trend": _vm.trend,
      "series": _vm.series
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }