var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.assetDimension && _vm.selectedTimeframeParam && _vm.assetsSelected ? _c('AssetDimensionCardWidget', {
    attrs: {
      "assetDimension": _vm.assetDimension,
      "selectedTimeframeParam": _vm.selectedTimeframeParam,
      "timeBuckets": _vm.timeBuckets,
      "assets": _vm.assetsSelected
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }