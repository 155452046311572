<template>
  <AssetDimensionCardWidget
    v-if="assetDimension && selectedTimeframeParam && assetsSelected"
    :assetDimension="assetDimension"
    :selectedTimeframeParam="selectedTimeframeParam"
    :timeBuckets="timeBuckets"
    :assets="assetsSelected"
  />
</template>

<script>
import { TimeframeMixin } from '@common/mixins'
import AssetDimensionCardWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionCardWidget'
import { useAssetStore } from '@/stores/assets'

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin],
  props: {
    widget: {
      type: Object,
      required: false,
    },
  },
  components: {
    AssetDimensionCardWidget,
  },
  computed: {
    dimensions() {
      const dimensions = this.widget?.dimensions ?? []
      return dimensions
    },
    assetDimension() {
      return this.dimensions[0]?.assetDimension ?? null
    },
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
  },
}
</script>
